import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
const Mangelist = ({ prodFitred }) => {
 
  return (
    <tbody>
           {
               prodFitred.map(post => (
                  
                   <tr key={post._id}>
                  
                       <td className="text-center">{post?.titreAr}  <br/>{post?.titreFr} </td>
                    
                       <td className="text-center">   
                       { post.categorie === "1"? <p>خضر <br/>légumes</p>:
                           post.categorie === "2"?  <p>غلال<br/>fruits</p> : post.categorie === "3"? <p>لحوم<br/>viende</p>:<p>أسماك<br/>poisson</p>}</td>
                       <td className="text-center"> 
                   
                       {post.prix[post.prix.length-1].prixMin}</td>
                    <td className="text-center"> 
                   
                   {post.prix[post.prix.length-1].prixMax}</td>
                   <td className="text-center"> 
                   
                   {post.prix[post.prix.length-1].prixMoy}</td> 
                       <td className="text-center"> 
                         
                       { moment.unix(post.prix[post.prix.length-1].updatedAt).format("DD/MM/YYYY HH:mm")}
                         
   
                            </td> 
                       
                     


                       <td className="text-center">
                           <div className="btn-group">
                         
                               <Link to={`/edit/${post._id}`} className="btn btn-success">
                                   <i className="fa fa-edit"></i>
                               </Link>

                              
                               {/* <Link to={{
           pathname: `/details/${post._id}`, 
           product:post
         }} className="btn btn-secondary">
                               <i className="fa fa-history" aria-hidden="true"></i>
                               </Link> */}

                             
                               <Link to={`/product/details/${post._id}`} className="btn btn-secondary">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                    </Link>
                               <Link to={`/delete/${post._id}`} className="btn btn-danger">
                                   <i className="fa fa-trash"></i>
                               </Link>
                            
                           </div>
                       </td>
                   </tr>
               ))
           }
       </tbody>
  );
};

export default Mangelist;
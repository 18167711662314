import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { Link } from "react-router-dom";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ScrollUpButton from "react-scroll-up-button";
import { useParams } from "react-router-dom";
import moment from 'moment';

const ProductDetails = () => {
  const { id } = useParams();
  const [chartData, setChartData] = useState({});
  const [titreAr, setTitreAr] = useState();
  const [titreFr, setTitreFr] = useState();
  const [pourcentage, setPourcentage] = useState();
  const [lastprice, setLastprice] = useState();
  const [pricelist, setPricelist] = useState([]);

  const [date, setDate] = useState();


  const chart = () => {
   
    let prix = [];
    let datechart = [];
   
    var chaine1 = "";
    var chaine2 = "";
    var chaine3 = "";
   
    axios(`https://alfalleh.orange.tn/api/product/${id}`)
      .then(res => {
     
     
          
        //"console.log('lol',(res.data.products[0].prix.reverse().slice(0,2)))
       
        setLastprice (res.data.products[0].prix.slice(0).reverse()[0].prixMoy)
        setTitreAr(res.data.products[0].titreAr)
        setTitreFr(res.data.products[0].titreFr)
        setDate(res.data.products[0].updatedAt)
        setPourcentage(res.data.products[0].pourcentage)
       setPricelist(res.data.products[0].prix)
      
        
        chaine1= res.data.products[0].titreAr;
        chaine2 =res.data.products[0].titreFr;
        chaine3=chaine1+" / "+chaine2;
        
      
        for (const dataObj of res.data.products[0].prix.slice(-7)) {

         
          prix.push((dataObj.prixMoy));

 
          datechart.push(( moment.unix(dataObj.updatedAt).format("DD/MM/YYYY HH:mm")));
        }


        
         
        setChartData({
          labels: datechart,
          datasets: [
            {
              label:  chaine3,
              data: prix,
              backgroundColor: ["#12AB69"],
              borderWidth: 4
            }
          ]
        });
      })
      .catch(err => {
        console.log(err);
      });
   
  
  };

  
 

 
  useEffect(() => {
    chart();
    
  }, []);
  
  return (
    <div className='container mt-2'>
       
    <Link to="/products" className="btn btn-outline-warning">
                         <i className="fa fa-arrow-left"></i> Retourner
                     </Link>
                     <div style={{  float: 'right'}}>
                     <ReactHTMLTableToExcel  
                                             className="btn btn-info"  
                                             table="emp"  
                                             filename={titreFr}
                                             sheet="Sheet"  
                                             buttonText="Export excel" />
                     </div>
                 <div className='container mt-2' >

                 
   <div className='container mt-2' align="center"   style={{ width: '900px',height:'100px'}} >
   <h3 align="center"  style={{ fontWeight: 'bold'}}>{titreAr} / {titreFr} { pourcentage === 0?  pourcentage:
                        pourcentage <= 1? pourcentage: 
                        "+" + pourcentage} %  </h3>
      <h5 align="center"  style={{ fontWeight: 'bold'}}>Prix du Kg en DT {lastprice} معدل
    
                   
      
      
      
    </h5>
      <p align="center"    style={{ fontWeight: 'bold'}}>Derinère mise à jour : { moment.unix(date).format("DD/MM/YYYY HH:mm")}</p>
     
     <div >

     <br/>
     <br/>
        <Line
     
          data={chartData}
          options={
           
            {
              
          
            responsive: true,
            plugins: {
            title: {
                display: true,
                
                text: 'Source: Union tunisienne de l\'agriculture et de la pêche',padding: {
                    top: 0,
                    bottom: 0
                },
                font: {
            
            size: 15,
           // weight: 'bold',
            lineHeight: 1.2,
          },
                
            }
        },
            scales: {
              yAxes: 
                
                {
        display: true,
        title: {
          display: true,
          text: '	Prix du Kg en DT(معدل)',
          color: '#12AB69',
          font: {
            //family: 'Times',
            size: 15,
           // weight: 'bold',
            lineHeight: 1.2,
          },
          padding: {top: 20, left: 0, right: 0, bottom: 0}
        }}
              ,
              xAxes: 
                {
                   
        display: true,
        title: {
          display: true,
          text: ' Date et Heure',
          color: '#12AB69',
          font: {
           
            size: 15,
            style: 'normal',
            lineHeight: 1.2
          },
          padding: {top: 0, left: 0, right: 0, bottom: 0}
        
      }
    }
                }
              
            }
          }
        />
      </div>

      <div className='container mt-2'  align="center" >

    
<br/>
<br/>

<table className="table" id="emp">
<thead>
<tr>
<th className="text-center">Date et Heure </th>
<th className="text-center">Prix du Kg en DT(عادي)</th>
<th className="text-center">Prix du Kg en DT(أقصى)</th>
<th className="text-center">Prix du Kg en DT(معدل)</th>

</tr>
</thead>
<tbody>
{
  pricelist.slice(0).reverse().map((post, index) => (

<tr key={index}>
<td className="text-center"> 

{ moment.unix(post.updatedAt).format("DD/MM/YYYY HH:mm")}           

</td>
<td className="text-center">{post.prixMin} </td>
<td className="text-center">{post.prixMax}</td>
<td className="text-center">{post.prixMoy}</td>
</tr>
))
}
</tbody>

</table>



</div>
     </div>
    
     </div>

     <ScrollUpButton

StopPosition={0}
ShowAtPosition={15}
EasingType='easeOutCubic'
AnimationDuration={500}
ContainerClassName='ScrollUpButton__Container'
TransitionClassName='ScrollUpButton__Toggled'
style={{}}
ToggledStyle={{}} />
    </div>
  );
};


        
export default ProductDetails;
    
import React,{useState,useEffect} from 'react';

import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import { Button } from 'react-bootstrap';

const Mangelist = ({ prodFitred }) => {
    const [isToggleOn, setIsToggleOn] = useState(false)
    const message = (id, ToggleState) => {
        setIsToggleOn(!isToggleOn)
        axios.get('https://alfalleh.orange.tn/api/news/masquer/'+id+'/'+!ToggleState)
        .then((result) => console.log(result))
       }
       
    useEffect(() => {
      
       setIsToggleOn(prodFitred.masquer)
         console.log(prodFitred.masquer)
      },[prodFitred]);
 
  return (
    
                        <tr key={prodFitred._id}>
                      
                                          
<td className="text-center"><img alt="scan"  style={{width:200, height:100}}  src={`https://alfalleh.orange.tn/images/${prodFitred.image}`} ></img> </td>
  <td className="text-center"> 
                            Créé le: { moment.unix(prodFitred.createdAt).format("DD/MM/YYYY")}
                             <br/> Mise à jour le  :{ moment.unix(prodFitred.updatedAt).format("DD/MM/YYYY")}</td>
                            <td className="text-center"> {prodFitred.titre}</td>
                            
                            <td className="text-center" >
                                <div className="btn-group">
                                    <Link to={`/news/edit/${prodFitred._id}`} className="btn btn-success">
                                        <i className="fa fa-edit"></i>
                                    </Link>
                                 
                                    <Link to={`/news/details/${prodFitred._id}`} className="btn btn-secondary">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                    </Link>
                                    <Link to={`/news/delete/${prodFitred._id}`} className="btn btn-danger">
                                   
                                        <i className="fa fa-trash"></i>
                                    </Link>
                              
                                    
                                {/* <Button onClick= {() => message(prodFitred._id,isToggleOn)} variant="outline-warning"> {isToggleOn ? "on" : "off"}</Button> */}
                                <div className="btn-group" onClick= {() => message(prodFitred._id,isToggleOn)}>
                          
                                {isToggleOn ? <Button  variant="warning">Online</Button> :  <Button  variant="info">Hors ligne</Button>}
                                
                                </div>
                                </div>
                            </td>
                        </tr>

                    


                
        
  );
};

export default Mangelist;
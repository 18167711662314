import React from 'react';
    import { useForm } from 'react-hook-form';
    import axios from 'axios';
    import { Link } from 'react-router-dom';
    import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Alert, AlertTitle } from '@material-ui/lab';




    const AdCreate = (props) => {

        const [selectedFile, setSelectedFile] = React.useState(null);
        const validationSchema = Yup.object(
            {
                url:Yup.string().url('url invalid'),
                picture: Yup
                .mixed()
                
                .test("fileSize", "File size too large, max file size is 10Mb", (file) =>
                file ? file[0].size <= 2000000: true
              )
              .test("fileType", "Type de fichier incorrect,nous acceptons seulement (png/jpg/jepg)", (file) =>
                file
                  ? ["image/png","image/jpg", "image/jpeg"].includes(file[0].type)
                  : true
              )
               }
              ).test("globalError", "url and image are required", function (value) {
                 if (value?.url && value?.picture) return true;
          
                 throw Error("vérifie la saisie des deux champs");

               });
          
        

       
       
        const { register, handleSubmit,errors } = useForm({
            resolver: yupResolver(validationSchema),
          
           
        });
       
      
      
        const onSubmit = async(data) => {
            var ads = new FormData()
             ads.append('url', data.url);
           // ads.append('postedBy', "606aed2816e3970c75983678");
            ads.append("image", selectedFile);
        
           axios.post('https://alfalleh.orange.tn/api/ads', ads).then(result => {
             
                props.history.push("/ads");
            })
        };
        
        function showErrors(errors) {
            return Object.keys(errors)?.map((err, index) => (
              <div key={index} className="ml-5">
                  <Alert severity="error">
  <AlertTitle>Erreur</AlertTitle>
  {errors[err].message} <strong> Vérifiez-le!</strong>
</Alert>
               
              </div>
            ));
          }
        return (
           <div className='container mt-3'>
               
        <div className="card">
           <h5 className="card-header">Ajouter une nouvelle actualité</h5>
           <div className="card-body">
           {Object.keys(errors).length ? showErrors(errors) : null}
               <form onSubmit={handleSubmit(onSubmit)}>
           
                   <div className="form-row ">
                       <div className="form-group col-6">
                       <label>Image * <small className="form-text text-danger"> * format de l'image (1029*432) et type(png/jpg/jepg) </small>  </label><br/>
                       <input ref={register} accept="image/jpg, image/jpeg, image/png" className="form-control"type="file"  name="picture" onChange={(e) => setSelectedFile(e.target.files[0])
                       
                       
                       }  />

                        {errors.picture && <p>{errors.picture.message}</p>}
                       
                       </div>
                    
                   </div>
                   <div className="form-row">
                   <div className="form-group col-6">
                           <label>Lien externe *</label>
                           <input name="url" placeholder= 'http://www.utap.org.tn/' type="text" ref={register} className={`form-control ${errors.url ? 'is-invalid' : ''}`} />
                           <div className="invalid-feedback">{errors.url?.message}</div>
                       </div>
                      
                   </div>
                
                   
                   <div className="form-group">
                   <Link to="/ads" className="btn btn-warning">
                                 <i className="fa fa-arrow-left"></i> Annuler
                            </Link>
                   &nbsp;
                    <button style={{float:'right'}}  type="submit" className="btn btn-success">Enregistrer <i className="fa fa-save"></i></button>
                   
             
                   </div>
               </form>
        
           </div>
       </div>
       </div>
            
        );
    }
    
    export default AdCreate;
    
import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const NewsCreate = (props) => {
    const validationSchema = Yup.object().shape({
        titre: Yup.string()
            .required('Titre est un champ obligatoire'),
        url: Yup.string().url('url invalide'),
         description: Yup.string(),
            categorie: Yup.string()
            .required('Catégorie est un champ obligatoire'),
           
       
          
       
    });
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const [test, setTest] = React.useState(false)

    const [selectedFile, setSelectedFile] = React.useState(null);
   const  handleChange =()=> {
       setTest(!test)
       
      }
      const onSubmit = data => {
        var news = new FormData()
       
        news.append('titre', data.titre);
      
        news.append('url', data.url);
        news.append('description', data.description);
        news.append('categorie', data.categorie);
        news.append('externe', data.externe);
        
       // news.append('postedBy', "606aed2816e3970c75983678");
       
        news.append("image", selectedFile);
    
      
    
        axios.post('https://alfalleh.orange.tn/api/news', news).then(result => {
           
           
            props.history.push("/news");
        }).catch(err=>{
         
    
           // console.log(err);
           alert(err)
        })
    };
    const content = test
    ?    <div className="form-row ">
                        
    <div className="form-group col-6">
    
    <label>Lien externe</label>
    <input type="text" name="url" ref={register}   className={`form-control ${errors.url? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.url?.message}</div></div>
 

</div>
    : <div className="form-group">  <label>Description</label>
                 

                        
    <textarea className="form-control" dir='rtl'  name="description"  rows="3" ref={register} ></textarea> </div>;


    return (
       
       
        <div className='container mt-3'>
         <div className="card">
            <h5 className="card-header">Ajouter une nouvelle actualité</h5>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row ">
                        
                        <div className="form-group col-6">
                           <label>Titre * </label> 
                    
                        
                        
                            <input name="titre" type="text" ref={register} className={`form-control ${errors.titre? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.titre?.message}</div>
                        </div>
                     
                    </div>
                    <div className="form-row ">
                    <div className="form-group col-6">
                       <label>Image * <small className="form-text text-danger"> * format de l'image (1029*432) et type(png/jpg/jepg) </small>  </label><br/>
                       <input ref={register}   accept="image/jpg, image/jpeg, image/png" type="file"  name="picture" onChange={(e) => setSelectedFile(e.target.files[0])}  className={`form-control ${errors.picture? 'is-invalid' : ''}`} />
                  
                            <div className="invalid-feedback">{errors.picture?.message}</div>

                       
                       </div></div>
                    <div className="form-row">
                    <div className="form-group col-6">
                            <label>Catégorie *</label>
                            <select name="categorie" ref={register} className={`form-control ${errors.categorie ? 'is-invalid' : ''}`}>
                              
                              
                      <option value="" > Choisir une catégorie</option>
                    
                            <option value="1">فلاحة-Agriculture</option>
                            <option value="2">صيد بحري-Pêche en mer</option>
                            <option value="3">اقتصاد-Économie</option>
                            </select>
                            <div className="invalid-feedback">{errors.categorie?.message}</div>
                        </div>
                       
                    </div>
                    <div className="form-group">
                        <label>  Lien externe 
                   </label> <br/>
                 <input
                                   ref={register}
                                      name="externe"
                                   //value={test}
                                   checked={ test } 
                                   onChange={ handleChange }
                                     type="checkbox"
                                  /> Oui
                         

</div>

{ content }

                    
                    <div className="form-group">
                    
                    <Link to="/news" className="btn btn-warning">
                            <i className="fa fa-arrow-left"></i> Annuler
                        </Link>
                   
                  
                     <button style={{float:'right'}}  type="submit" className="btn btn-success" >Enregistrer <i className="fa fa-save"></i></button>
                    
                   
                    </div>
                </form>
            </div>
        </div>
        </div>

        
    );
}

export default NewsCreate;

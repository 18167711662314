import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './ProductList.css';

const CreateProduct = (props) => {
    const validationSchema = Yup.object().shape({
        categorie: Yup.string()
            .required('Catégorie est un champ obligatoire'),
        titreFr: Yup.string()
            .required('Nom du produit en Français est un champ obligatoire'),
         titreAr: Yup.string()
            .required('Nom du produit en Arabe est un champ obligatoire'),
            prixMin: Yup.number().typeError('prix min est un champ obligatoire').required('prix min est un champ obligatoire').positive('prix doit être positif'),
       
          
        prixMax: Yup.number().typeError('prix max est un champ obligatoire').required('prix max est un champ obligatoire').positive('prix doit être positif'),
         
        prixMoy: Yup.number().typeError('prix moy est un champ obligatoire').required('prix moy est un champ obligatoire').positive('prix doit être positif'),
     
       // acceptTerms: Yup.bool()
           // .oneOf([true], 'Accept Ts & Cs is required')
    });
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const onSubmit = data => {
        console.log('data',data.titreAr);
        /* const product = { 
        
            ...data,
          
            postedBy: "606aed2816e3970c75983678"
        } */
        const product = {

            titreAr: data.titreAr,
            titreFr: data.titreFr,
        
            prix: [
                {
                 // prix : data.prix
                 prixMin: data.prixMin,
                 prixMax: data.prixMax,
                  prixMoy: data.prixMoy
                }
              ],
            categorie: data.categorie,
         
        }
        console.log('product',product);
        axios.post('https://alfalleh.orange.tn/api/product', product).then(result => {
            console.log(result);
           
            props.history.push("/products");
        }).catch(err=>{
         
            alert('Ce produit existe déjà')
            console.log(err);
        })
    };
    

    return (
        // <div className='container mt-3'>
        // <div className="card">
        //     <div className="card-body">
        //         <h5 className="card-title">Ajouter un produit</h5>
        //         <div className="card-text">
        //             <form onSubmit={handleSubmit(onSubmit)}>
        //                 <div className="form-group">
        //                     <label>Nom  du produit en Arabe *</label>
        //                     <input type="text" className="form-control"  dir='rtl'name="titreAr" ref={register({ required: true })} />
        //                     <small className="form-text text-danger">{errors.titreAr && 'Nom du produit en Arabe est un champ obligatoire'}</small>
        //                 </div>
        //                 <div className="form-group">
        //                     <label>Nom du produit en Français *</label>
        //                     <input type="text" className="form-control" name="titreFr" ref={register({ required: true })} />
        //                     <small className="form-text text-danger">{errors.titreFr && 'Nom du produit en Français est un champ obligatoire'}</small>
        //                 </div>
                        
        //                 <div className="form-group">
        //                     <label>Catégorie *</label>


        //                 <select className="form-control" name="categorie"  ref={register({ required: true })} aria-label="Default select example">
        //                     <option > Choisir une catégorie</option>
        //                     <option value="1">خضر-légume</option>
        //                     <option value="2">غلال-fruit</option>
        //                     <option value="3">لحوم-viende</option>
        //                     <option value="4">أسماك-poisson</option>
                           
                           
        //                 </select>
        //                 <small className="form-text text-danger">{errors.categorie&& 'Catégorie invalide'}</small>
        //                 </div>
                      
                    

                      
                        

                      
        //                 <div className="form-group">
        //                     <label>Prix du Kg en DT</label>
        //                     <input type="number"  step="0.01" className="form-control" name="prix" ref={register({ required: true })} />
        //                     <small className="form-text text-danger">{errors.prix && 'Prix  est un champ obligatoire'}</small>
        //                 </div>

        //                 <div className="form-group">
        //                     <label>Prix du Kg en DT</label>
        //                     <input type="number"  step="0.01" className="form-control" name="prix" ref={register({ required: true })} />
        //                     <small className="form-text text-danger">{errors.prix && 'Prix  est un champ obligatoire'}</small>
        //                 </div>
        //                 <div className="form-group">
        //                     <label>Prix du Kg en DT</label>
        //                     <input type="number"  step="0.01" className="form-control" name="prix" ref={register({ required: true })} />
        //                     <small className="form-text text-danger">{errors.prix && 'Prix  est un champ obligatoire'}</small>
        //                 </div>

                    
        //                 <Link to="/products" className="btn btn-warning">
        //                     <i className="fa fa-arrow-left"></i> Annuler
        //                 </Link>
        //                 &nbsp;
        //                 <button type="submit" className="btn btn-success">Enregistrer <i className="fa fa-save"></i></button>
                     
        //             </form>
        //         </div>
        //     </div>
        // </div></div>


       
        <div className='container mt-3'>
         <div className="card">
            <h5 className="card-header">Ajouter un produit</h5>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row ">
                        
                        <div className="form-group col-6">
                           <label>Nom du produit en Français *</label> 
                    
                        
                        
                            <input name="titreFr" type="text" ref={register} className={`form-control ${errors.titreFr ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.titreFr?.message}</div>
                        </div>
                        <div className="form-group col-6">
                            <label>Nom du produit en Arabe *</label>
                            <input name="titreAr" type="text" ref={register} dir='rtl' className={`form-control ${errors.titreAr ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.titreAr?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-4">
                            <label>Catégorie *</label>
                            <select name="categorie" ref={register} className={`form-control ${errors.categorie ? 'is-invalid' : ''}`}>
                              
                              
                      <option value="" > Choisir une catégorie</option>
                            <option value="1">خضر-légume</option>
                            <option value="2">غلال-fruit</option>
                            <option value="3">لحوم-viende</option>
                            <option value="4">أسماك-poisson</option>
                            </select>
                            <div className="invalid-feedback">{errors.categorie?.message}</div>
                        </div>
                       
                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <label>Prix du Kg en DT(عادي) *</label>
                            <input name="prixMin" type="number"  step="0.01" ref={register} className={`form-control ${errors.prixMin ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.prixMin?.message}</div>
                        </div>
                        <div className="form-group col">
                            <label>Prix du Kg en DT (أقصى) *</label>
                            <input name="prixMax" type="number"  step="0.01" ref={register} className={`form-control ${errors.prixMax ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.prixMax?.message}</div>
                        </div>
                        <div className="form-group col">
                            <label>Prix du Kg en DT (معدل) *</label>
                            <input name="prixMoy" type="number"  step="0.01" ref={register} className={`form-control ${errors.prixMoy ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.prixMoy?.message}</div>
                        </div>
                    </div>
                    
                    <div className="form-group">
                    
                    <Link to="/products" className="btn btn-warning">
                            <i className="fa fa-arrow-left"></i> Annuler
                        </Link>
                   
                  
                     <button style={{float:'right'}}  type="submit" className="btn btn-success" >Enregistrer <i className="fa fa-save"></i></button>
                    
                   
                    </div>
                </form>
            </div>
        </div>
        </div>

        
    );
}

export default CreateProduct;

import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const EditAd = (props) => {
    const { id } = useParams();
   

 const [img, setImg] = React.useState(null)
 //const [imgVal, setImgVal] = React.useState(null)
    const [selectedFile, setSelectedFile] = React.useState(null);
 
    const { register, handleSubmit, errors, setValue } = useForm();

    useEffect(() => {
            
        axios.get(`https://alfalleh.orange.tn/api/ads/${id}`).then(result => {
            
           
            setValue("id", result.data.ad[0]._id);
           
            setValue("url", result.data.ad[0].url);
           
          
         
            setImg(`https://alfalleh.orange.tn/images/${result.data.ad[0].image}`)
            //setImgVal(result.data.ad[0].image)
          
            
            setValue("body", result.data.body);
         
          
        })
    }, [id, setValue]);

    
    const onSubmit = async (data) => {
        console.log(data);
        console.log(id);
       
        const formData = new FormData();
        formData.append('url',data.url);
         formData.append("image", selectedFile); 
       
      
        axios.post(`https://alfalleh.orange.tn/api/ads/edit/${id}`, formData).then(result => {
            console.log(result);
            props.history.push("/ads");
        }).catch((e) => console.log(e.message))
    };

    return (
        <div className='container mt-3'>
        <div className="card">
        <h5 className="card-header">Mise à jour </h5>
            <div className="card-body">
                
                <div className="card-text">
                    <form onSubmit={handleSubmit(onSubmit)}>

                
                       
                        <div className="form-group">
                        <label>Image</label><br/>
                           
                            <img alt="scan"  style={{width:200, height:100}}  src={img} ></img> 
                            <small className="form-text text-danger">  * format de l'image (1029*432) et type(png/jpg/jepg) </small> 
                            <input type="file" className="form-control"  accept="image/jpg, image/jpeg, image/png" name="file" ref={register({ required: false })}  onChange={(e) => setSelectedFile(e.target.files[0])}   />
                            <small className="form-text text-danger">{errors.file && 'Image invalide'}</small>
                        </div>
                        

                      
                        <div className="form-group">
                            <label>Lien externe</label>
                            <input type="text" className="form-control" name="url" ref={register({ required: false })} />
                            <small className="form-text text-danger">{errors.url && 'Url invalid'}</small>
                        </div>
                 
           
                    
                        <Link to="/ads" className="btn btn-warning">
                            <i className="fa fa-arrow-left"></i> Annuler
                        </Link>
                        &nbsp;
                        <button   style={{float:'right'}} type="submit" className="btn btn-success">Enregistrer <i className="fa fa-save"></i></button>
                     
                    </form>
                </div>
            </div>
        </div>
        </div>
    );
}

export default EditAd;

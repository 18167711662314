import React, { useState, useEffect } from 'react';
import Posts from './ManageAdList';
import Pagination from './PaginationAd';
import axios from 'axios';
import '../ProductList.css';
import { Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';

const AdList = () => {
  

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const [ads, setAds] = useState([]);
  const [load, setLoad] = useState(false);
  
  useEffect(() => {
    const fetchPosts = async () => {

      await axios.get('https://alfalleh.orange.tn/api/ads/web')
      .then(res => {
      
        setAds(res.data.ads);
       
        setLoad(true);
      })
      .catch(err => {
       console.log(err)
        setLoad(true)
      })
     
     
    };

    fetchPosts();
  }, []);
 
 
  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = ads.slice(indexOfFirstPost, indexOfLastPost);
  //console.log(currentPosts);
  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);
  if (load) {
  return (
    <div>
    {/* {error ? <>
    <br/>
    <br/>
    <br/>
      <Snackbar open={true} anchorOrigin={{ vertical: 'center', horizontal: 'center' }}>
        <Alert severity="error">
       
        Le système est en panne    <Button  variant="outline-danger" onClick={refreshPage} >
        <i class="fa fa-refresh fa-10x"  aria-hidden="true" style={{color:'white'}}></i>
          </Button>
       
       
     
        </Alert>

       
        
      

        
      </Snackbar>

    </> : */}
    <div className='container mt-2'>
  <Link to={`/ads/create`} className="btn btn-success">
                                        <i className="fa fa-plus"></i> Ajouter une nouvelle bannière
                                    </Link>
                                   
                                 
                                    <br/>
                                    <br/>

                                            
<table className="table"  >
       
       <thead>
       <tr>
                    <th className="text-center">Image</th>
                    <th className="text-center">Date</th>
                    <th className="text-center">Lien </th>
                    <th className="text-center">Action</th>

                    
                </tr>
       </thead>
      <tbody>
      { currentPosts.map((post, index) =>(
        <Posts key={index} ads={post}  />
      ))

      }
      </tbody>
      

      
       </table>
     
      <Pagination
         className="pagination"
        postsPerPage={postsPerPage}
        totalPosts={ads.length}
        paginate={paginate}
      />
    </div>
  
    </div>
  );  }else{  return (
    <div className='container mt-2'>
      <LinearProgress  
        
      />
    </div>
  );
  }
 
};

export default AdList;

import React, { useState, useEffect } from 'react';
import Posts from './ManageList.js';
import Pagination from './Pagination';
import axios from 'axios';

import { Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';




const ProductList = () => {
  

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [prod, setProds] = useState([]);
  const [load, setLoad] = useState(false);
  //const [error, setError] = useState('');
  const [prodFitred, setProdsFitred] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
     
     await axios.get('https://alfalleh.orange.tn/api/product')
      .then(res => {
        setProds(res.data.products);
        setProdsFitred(res.data.products)
        console.log(res.data.products)
        setLoad(true);
      })
      .catch(err => {
        console.log(err)
      
        setLoad(true)
      })
      
     
    };

    fetchPosts();
  }, []);
 
  const searchFilterFunction = event => {
    // console.log(prod);
     /* 
     console.log(event.target.value); */
         var text = event.target.value;
     
         const products = prod.filter((element) => element.categorie.toString().includes(text.toString()))
         
         setProdsFitred (products)

     

 };
  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = prodFitred.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);
  if (load) {
  return (
    <div> 
    
    
    {/* {error ? <>

      <br/>
    <br/>
    <br/>
     <Snackbar open={true} anchorOrigin={{ vertical: 'center', horizontal: 'center' }}>
        <Alert severity="error">
       
        Le système est en panne    <Button  variant="outline-danger" onClick={refreshPage} >
        <i class="fa fa-refresh fa-10x"  aria-hidden="true" style={{color:'white'}}></i>
          </Button>
       
       
     
        </Alert>

       
        
      

        
      </Snackbar>

    </> : */}
    <div className='container mt-2'>
    
  <Link to={`/create`} className="btn btn-success">
                                        <i className="fa fa-plus"></i> Ajouter un produit 
                                    </Link>
                                    <div className="push-right">


                        <select className="form-control" name="categorie" onChange={(val) => searchFilterFunction(val)}   aria-label="Default select example">
                            <option value=""> filtre par catégorie</option>
                            <option value="1">خضر-légume</option>
                            <option value="2">غلال-fruit</option>
                            <option value="3">لحوم-viende</option>
                            <option value="4">أسماك-poisson</option>
                           
                           
                        </select>
                       
                        </div>
                                    <br/>
                                    <br/>
        
                                            
<table className="table"  >
       
       <thead>
           <tr>
               <th  className="text-center" >Produit </th>
               <th className="text-center">Catégorie </th>
               <th className="text-center">Prix du Kg en DT(عادي)</th>
               <th className="text-center">Prix du Kg en DT(أقصى)</th>
               <th className="text-center">Prix du Kg en DT(معدل)</th>
               <th className="text-center">Date et Heure </th>
               <th className="text-center">Action</th>
           </tr>
       </thead>
      
       <Posts prodFitred={currentPosts}  />

      
       </table>
     
      <Pagination
         className="pagination"
        postsPerPage={postsPerPage}
        totalPosts={prodFitred.length}
        paginate={paginate}
      />
      
    
    </div>
    
{/* } */}
    </div>
  );
}else {  return (
  <div className='container mt-2'>
    <LinearProgress  
      
    />
  </div>
);
}

}
export default ProductList;

import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
const validationSchema = Yup.object().shape({
      
    title: Yup.string()
        .required('Titre de la notification est un champ obligatoire'),
        body: Yup.string()
        .required('Contenu de la notification est un champ obligatoire'),
   
});
const CreateNotification = (props) => {
  
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const onSubmit = data => {
    
       
        const notification = {

            title: data.title,
            body: data.body,
        
           
        }
        console.log('notification',notification);
        axios.post('https://alfalleh.orange.tn/api/notification', notification).then(result => {
            console.log(result);
          alert('notification envoyée')
            //props.history.push("/");

            window.location.reload()
            

        }).catch(err=>{
         
           
            console.log(err);
        })
    };
    

    return (
      
                    

                      
                        


       
        <div className='container mt-3'>
         <div className="card">
            <h5 className="card-header">Ajouter une notfication</h5>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row ">
                        
                        <div className="form-group col-12">
                            <label >Titre de la notification *</label>
                            <input name="title" type="text" ref={register} dir='rtl' className={`form-control ${errors.title ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.title?.message}</div>
                        </div>
                       
                    </div>
                   

                    <div className="form-row">
                    <div className="form-group col-12">
                    <label>Contenu de la notification *</label>
                           
                     <textarea dir='rtl' className={`form-control ${errors.body ? 'is-invalid' : ''}`} name="body"  rows="3" ref={register} ></textarea> 
          
                    <div className="invalid-feedback">{errors.body?.message}</div>
                        </div>
                       
                    </div>
                  
                      
                    <div className="form-group">
                    <Link to="/" className="btn btn-warning">
                            <i className="fa fa-arrow-left"></i> Annuler
                        </Link>
                    &nbsp;
                     <button style={{float:'right'}}  type="submit" className="btn btn-success">Enregistrer <i className="fa fa-save"></i></button>
                    
              
                    </div>
                </form>
            </div>
        </div>
        </div>

        
    );
}

export default CreateNotification;

import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';


const EditProduct = (props) => {
    const { id } = useParams();
   const [prodNameAr, setProdNameAr] = useState()
   const [prodNameFr, setProdNameFr] = useState()
    const { register, handleSubmit, setValue } = useForm();

    useEffect(() => {
        axios.get(`https://alfalleh.orange.tn/api/product/${id}`).then(result => {
            
           
            setValue("id", result.data.products[0]._id);
            setValue("titreAr", result.data.products[0].titreAr);
            setProdNameFr(result.data.products[0].titreFr)
            setProdNameAr(result.data.products[0].titreAr)
            let arr =result.data.products[0].prix.reverse()[0].prix
            console.log(arr)
             setValue("prixMin", result.data.products[0].prix[0].prixMin);
             setValue("prixMax", result.data.products[0].prix[0].prixMax);
             setValue("prixMoy", result.data.products[0].prix[0].prixMoy);


            
            //setValue("prix", result.data.products[0].prix[0].prix);
            //setValue("body", result.data.body);
        })
    }, [id, setValue]);

    const onSubmit = data => {
        const prix = { //prix: data
            
           prixMin: data.prixMin,prixMax: data.prixMax,prixMoy: data.prixMoy
        }
       
        // axios.put(`https://alfalleh.orange.tn/api//product/edit/${id}`, prix).then(result => {
        //     console.log(prix)
          
        //     props.history.push("/products");
        // }).catch(err=>{
         
        //     alert('Le prix du produit ne peut pas être modifié ')
        //     console.log(err);
        // })



        axios.put(`https://alfalleh.orange.tn/api/product/update/${id}`, prix).then(result => {
            console.log(prix)
          
            props.history.push("/products");
        }).catch(err=>{
         
    
            console.log(err);
            props.history.push("/products");
        })
    };

    return (
        // <div className="card">
        //     <div className="card-body">
        //         <h5 className="card-title">Mise à jour du prix<br/> 
        //         <small className="form-text text-danger"> * le produit ne peut être modifié qu'après 24h de sa création </small> </h5>
              
             
        //         <div className="card-text">
              
        //             <form onSubmit={handleSubmit(onSubmit)}>
                        
        //                 <div className="form-group">
        //                     <label>Prix DT </label>
        //                     <input type="number" step="0.01"  className="form-control" name="prix" ref={register({ required: true })} />
        //                     <small className="form-text text-danger">{errors.prix && 'prix invalid'}</small>
        //                 </div>
                        
                       

        //                 <Link to="/products" className="btn btn-warning">
        //                     <i className="fa fa-arrow-left"></i> Annuler
        //                 </Link>
        //                 &nbsp;
        //                 <button type="submit" className="btn btn-success">Enregistrer <i className="fa fa-save"></i></button>
        //             </form>
        //         </div>
        //     </div>
        // </div>


        <div className='container mt-3'>
        <div className="card">
           <h5 className="card-header" >Mise à jour <strong>{prodNameFr} / {prodNameAr}</strong> </h5>
           <div className="card-body">
               <form onSubmit={handleSubmit(onSubmit)}>
               <div className="form-row">
                        <div className="form-group col">
                            <label>Prix du Kg en DT(عادي) </label>
                            <input name="prixMin" type="number"  step="0.01" ref={register}  className={`form-control`} />
                          
                        </div>
                         <div className="form-group col">
                            <label>Prix du Kg en DT (أقصى)</label>
                            <input name="prixMax" type="number"  step="0.01" ref={register} className={`form-control`} />
                           
                        </div>
                        <div className="form-group col">
                            <label>Prix du Kg en DT (معدل)</label>
                            <input name="prixMoy" type="number"  step="0.01" ref={register} className={`form-control`} />
                           
                        </div> 
                    </div>

                    <div className="form-group col">
                         <Link to="/products" className="btn btn-warning">
                           <i className="fa fa-arrow-left"></i> Annuler
                        </Link>
                       &nbsp;
 
                      <button  style={{float:'right'}} type="submit" className="btn btn-success">Enregistrer <i className="fa fa-save"></i></button>
                      </div>
                    </form>
                    </div></div></div>

    );
}

export default EditProduct;

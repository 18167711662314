import React, { useState, useEffect } from 'react';
import Posts from './ManageNewsList.js';
import Pagination from './PaginationNews';
import axios from 'axios';
import '../ProductList.css';
import { Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';

const NewsList = () => {
  

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [news, setNews] = useState([]);
  const [prodFitred, setProdsFitred] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
     
       await axios.get('https://alfalleh.orange.tn/api/news/web')
        .then(res => {
        setNews(res.data.news);
      setProdsFitred(res.data.news)
       
        setLoad(true);
      })
      .catch(err => {
console.log(err)
      
        setLoad(true)
      })
      
    };

    fetchPosts();
  }, []);
 
 
  const searchFilterFunction = event => {
    // console.log(prod);
     /* 
     console.log(event.target.value); */
         var text = event.target.value;
     
         const products = news.filter((element) => element.categorie.toString().includes(text.toString()))
         
         setProdsFitred (products)

     

 };
  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = prodFitred.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  if (load) {
  return (
    <div> 
    
    
   
    <div className='container mt-2'>
  <Link to={`/news/create`} className="btn btn-success">
                                        <i className="fa fa-plus"></i> Ajouter une nouvelle actualité
                                    </Link>
                                    <div className="push-right">


<select className="form-control" name="categorie" onChange={(val) => searchFilterFunction(val)}   aria-label="Default select example">
    <option value=""> filtre par catégorie</option>
    <option value="1">فلاحة-Agriculture</option>
    <option value="2">صيد بحري-Pêche en mer</option>
    <option value="3">اقتصاد-Économie</option>

   
   
</select>

</div>
                                 
                                    <br/>
                                    <br/>

                                            
<table className="table"  >
       
       <thead>
       <tr>
                   <th className="text-center">Image</th>
                    <th className="text-center">Date et Heure</th>
                    <th className="text-center">Titre</th>
                    <th className="text-center">Action</th>


                    
                </tr>
       </thead>
      
     

       <tbody>
      { currentPosts.map((post, index) =>(
        <Posts key={index} prodFitred={post}  />
      ))

      }
      </tbody>
       </table>
     
      <Pagination
         className="pagination"
        postsPerPage={postsPerPage}
        totalPosts={prodFitred.length}
        paginate={paginate}
      />
    </div>
  
    </div>
  );
  
}else {  return (
  <div className='container mt-2'>
    <LinearProgress  
      
    />
  </div>
);
}
}

export default NewsList;

import React from 'react'

import Jumbotron from 'react-bootstrap/Jumbotron'
//import Container from   'react-bootstrap/Container'
//import Button from   'react-bootstrap/Button'
function DashIndex() {
    return (
        <div className='container mt-5'>

 
       
       <Jumbotron>
  <h1>Bonjour, {localStorage.getItem('role')}!</h1>
  <p>
bienvenue  dans l'espace UTAP
  </p>
  {/* <p>
    <Button variant="primary" onClick{()=> }>Learn more</Button>
  </p> */}
</Jumbotron>
        </div>
    )
}

export default DashIndex

import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import axios from 'axios';
import { Button } from 'react-bootstrap';


const Mangelist = ({ ads }) => {
    const [isToggleOn, setIsToggleOn] = useState(false)
    const message = (id, ToggleState) => {
        setIsToggleOn(!isToggleOn)
        axios.get('https://alfalleh.orange.tn/api/masquer/'+id+'/'+!ToggleState)
        .then((result) => console.log(result))
       }
       
    useEffect(() => {
    
       setIsToggleOn(ads.masquer)
         console.log(ads)
      },[ads]);
    

 return (
   

    <tr key={ads._id}>
           
 <td className="text-center"><img alt="scan"  style={{width:200, height:100}}  src={`https://alfalleh.orange.tn/images/${ads.image}`} ></img> </td>
  <td className="text-center">  Mise à jour le  :{ moment.unix(ads.updatedAt).format("DD/MM/YYYY")}</td>
   <td className="text-center"> {ads.url}</td>
   <td className="text-center">
        <div className="btn-group">
      
        
     
        
            <Link to={`/ads/edit/${ads._id}`} className="btn btn-success">
                <i className="fa fa-edit"></i>
            </Link>
           
            <Link to={`/ads/delete/${ads._id}`} className="btn btn-danger">
                <i className="fa fa-trash"></i>
            </Link>
            <div className="btn-group" onClick= {() => message(ads._id,isToggleOn)}>
                          
                          {isToggleOn ? <Button  variant="warning">Online</Button> :  <Button  variant="info">Hors ligne</Button>}
                          
                          </div>
                                
            
            
        </div>
    </td>
</tr>      
                       

                   
  );
};

export default Mangelist;
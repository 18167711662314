import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';

const DeleteNews = (props) => {
    const { id } = useParams();
    const [post, setPost] = useState();

    useEffect(() => {
        axios.get(`https://alfalleh.orange.tn/api/news/details/${id}`).then(result => {
            setPost(result.data.news[0]);
            console.log(result.data.news[0])
        })
    }, [id]);

    const handleRemovePost = () => {
        axios.delete(`https://alfalleh.orange.tn/api/news/${id}`).then(result => {
            props.history.push("/news");
        })
    }

    return (
        <div className='container mt-3'>
               
        <div className="card">
           <h5 className="card-header">Voulez-vous vraiment supprimer cet élément ?</h5>
           <div className="card-body">
         
            <h4  style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}><strong>{post?.titre}</strong></h4>

            <br />
            
            <div className="form-group col">
            <Link to="/news" className="btn btn-outline-warning">
                    <i className="fa fa-arrow-left"></i>    Annuler
                </Link>
                <button  style={{float:'right'}} onClick={handleRemovePost} className="btn btn-outline-danger">
                    Supprimer <i className="fa fa-trash"></i>
                </button>
            </div>
        
           </div>
       </div>
       </div>
       
    );
}

export default DeleteNews;

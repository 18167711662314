import React ,{useState}from 'react';
import { BrowserRouter, Route, NavLink, Redirect } from 'react-router-dom';
import logo from './UTAP_AppIcon.png';
import CreateProduct from './components/CreateProduct';
import ProductList from './components/ProductList';
import ProductDetails from './components/ProductDetails';
import DeleteProduct from './components/DeleteProduct';
import EditProduct from './components/EditProduct';
import AdList from './components/ads/AdList';
import NewsList from './components/news/NewsList';
import NewsDetails from './components/news/NewsDetails';
import {BoxArrowLeft } from 'react-bootstrap-icons';
import CreateNotification from './components/notifications/CreateNotification';
import { Button } from 'react-bootstrap';
import './Manager.css';

import AdCreate from './components/ads/AdCreate';
import DeleteAd from './components/ads/DeleteAd';
import EditAd from './components/ads/EditAd';
import NewsCreate from './components/news/NewsCreate';
import EditNews from './components/news/EditNews';
import DeleteNews from './components/news/DeleteNews'
import DashIndex from './components/news/DashIndex';


const Manager = () => {
    
    const [islogout, setIslogout] = useState(false)

    const signOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("role");
       
        setIslogout(true)
      
      };
      if (islogout) {
        return <Redirect to="/login" />;
      }
    return (
        <BrowserRouter>

           <nav className="navbar navbar-expand-md navbar-light bg-light">
           
           <img className="App-logo" src={logo} alt="Logo"   />
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                    
                       
                       {localStorage.getItem("role")=== 'admin'? <li className="nav-item">
                            <NavLink className="nav-link" activeStyle={{ fontWeight: 'bold' }} to="/news">Gestion des Actualités</NavLink>
                        </li>: <li className="nav-item">
                            <NavLink className="nav-link" activeStyle={{ fontWeight: 'bold' }} to="/news">Gestion des Actualités</NavLink>
                        </li>}


                        {localStorage.getItem("role")=== 'admin'? <li className="nav-item">
                            <NavLink className="nav-link" activeStyle={{ fontWeight: 'bold' }} to="/ads"> Gestion de la Banière</NavLink>
                        </li>: <div></div>}

                        {localStorage.getItem("role")=== 'admin'? <li className="nav-item">
                            <NavLink className="nav-link" activeStyle={{ fontWeight: 'bold' }} to="/products">Gestion des  produits</NavLink>
                        </li>: <div></div>}
                        
                        {localStorage.getItem("role")=== 'admin'? <li className="nav-item">
                            <NavLink className="nav-link" activeStyle={{ fontWeight: 'bold' }} to="/notifcations">Notification</NavLink>
                        </li>: <div></div>}


                       
                       
                     
 

      
                    </ul>
                    <p className="push-right">
        
        <Button variant="outline-success" onClick={signOut}> <BoxArrowLeft variant="outline-success"  /> Se déconnecter </Button>
     
        </p>     
                </div>
            </nav>
            <div className="container">
         
               <Route path="/" exact component={DashIndex}></Route>
               <Route path="/products" exact component={ProductList}></Route>
                <Route path="/create" exact component={CreateProduct}></Route>
                <Route path="/product/details/:id" exact component={ProductDetails}></Route>
                <Route path="/delete/:id" exact component={DeleteProduct}></Route>
                <Route path="/edit/:id" exact component={EditProduct}></Route>
             
               <Route path="/ads" exact component={AdList}></Route>
               <Route path="/notifcations" exact component={CreateNotification}></Route>
                <Route path="/ads/create" exact component={AdCreate}></Route>
         <Route path="/ads/delete/:id" exact component={DeleteAd}></Route>
             <Route path="/ads/edit/:id" exact component={EditAd}></Route>
              <Route path="/news" exact component={NewsList}></Route>
                <Route path="/news/create" exact component={NewsCreate}></Route>
             <Route path="/news/details/:id" exact component={NewsDetails}></Route>
             <Route path="/news/delete/:id" exact component={DeleteNews}></Route>
             <Route path="/news/edit/:id" exact component={EditNews}></Route>
             
              
                
            </div>
        </BrowserRouter>
    );
}

export default Manager;
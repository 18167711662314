import  React from "react";
import "./styles.css";
import Login from "./components/login/Login";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Manager from "./Manager";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <ProtectedRoute path="/">
          <Manager />
        </ProtectedRoute>

      
        <Route exact path="/">
          <Redirect exact from="/" to="products" />
        </Route>
        <Route path="*">
          <Redirect from="/" to="products" />
        </Route>
      </Switch>
      <div>
        <center> 
          <br />
          
        </center>
      </div>
    </Router>
  );
}

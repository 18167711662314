import React, { useEffect ,useState} from 'react';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import '../ProductList.css';
const EditNews = (props) => {
    const { id } = useParams();
 
   const [img, setImg] = useState(null)
   const [text, setText] = useState()
  
   // const [imgVal, setImgVal] = React.useState(null)
       const [selectedFile, setSelectedFile] = useState(null);
    const { register, handleSubmit, errors, setValue } = useForm();
   
    useEffect(() => {
        axios.get(`https://alfalleh.orange.tn/api/news/details/${id}`).then(result => {
           
        setImg(`https://alfalleh.orange.tn/images/${result.data.news[0].image}`)
           // setImgVal(result.data.news[0].image)
          
           
            setValue("id", result.data.news[0]._id);
            setValue("titre", result.data.news[0].titre);
            setText(result.data.news[0].titre)
             if (result.data.news[0].url === 'undefined')
             { setValue("url", '');
             }else
             {   setValue("url", result.data.news[0].url);}
         
             if (   result.data.news[0].description === 'undefined')
             {
                setValue("description", '');
             }else
             {       setValue("description", result.data.news[0].description);;}
         
              
         
           
            
             setValue("categorie", result.data.news[0].categorie);
             setValue("externe", result.data.news[0].externe);
            // setValue("masquer", result.data.news[0].masquer);
            //setValue("file", result.data.news[0].image);
            
          // setValue("body", result.data.body);
        })
    }, [id, setValue]);

    const onSubmit = async (data) => {
       
        const formData = new FormData();
        formData.append('url',data.url);
         formData.append("image", selectedFile); 
       
         formData.append('titre', data.titre);
   
       
         formData.append('description', data.description);
         formData.append('categorie', data.categorie);
         formData.append('externe', data.externe);
         
         //formData.append('masquer', data.masquer);
         
       
        axios.post(`https://alfalleh.orange.tn/api/news/edit/${id}`, formData).then(result => {
          
            props.history.push("/news");
        }).catch((e) => console.log(e.message))
    };

  
    return (
        <div className='container mt-3'>
        <div className="card">
       
           <h5 className="card-header">Mise à jour : {text}</h5>
            <div className="card-body">
            
               
                <div className="card-text">
                
                    <form onSubmit={handleSubmit(onSubmit)}>
            
                        <div className="form-group">
                            <label>Titre </label>
                            <input type="text" className="form-control"  name="titre" ref={register({ required: false })} />
                            <small className="form-text text-danger">{errors.titre && 'Titre invalide'}</small>
                        </div>
                        <div className="form-group">
                      
                            <label>Image</label><br/>
                            <img alt="scan"  style={{width:200, height:100}}  src={img} ></img> 
                            <small className="form-text text-danger"> * format de l'image (1125*843) </small> 
                            <input type="file" className="form-control"  accept="image/jpg, image/jpeg, image/png" name="file" ref={register({ required: false })} onChange={(e) => setSelectedFile(e.target.files[0])} />
                            <small className="form-text text-danger">{errors.file && 'Image invalide'}</small>
                        </div>
                        
                        <div className="form-group">
                            <label>Catégorie</label>


                        <select className="form-control" name="categorie"  ref={register({ required: false })} aria-label="Default select example">
                        <option > Choisir une catégorie</option>
                            <option value="1">فلاحة-Agriculture</option>
                            <option value="2">صيد بحري-Pêche en mer</option>
                            <option value="3">اقتصاد-Économie</option>
                           
                           
                        </select>
                        <small className="form-text text-danger">{errors.categorie&& 'Categorie invalide'}</small>
                        </div>
                      
                    

                      
                        

                      
                        <div className="form-group">
                            <label>Url</label>
                            <input type="text" className="form-control" name="url" ref={register({ required: false })} />
                            <small className="form-text text-danger">{errors.url && 'Url invalid'}</small>
                        </div>
                        <div className="form-group">
                        <label>  Lien externe 
                   </label> <br/>
                 <input
                                   ref={register({ required: false })}
                                      name="externe"
                                   value={true}
                                     type="checkbox"
                                  /> Oui
                         
                   <small className="form-text text-danger">{errors.externe && 'externe invalid'}</small>

</div>
                        <div className="form-group">
                            <label>Description</label>
                 

                        
                   <textarea className="form-control"  name="description" rows="3" ref={register({ required: false })} ></textarea>
                     <small className="form-text text-danger">{errors.description && 'Description invalid'}</small>
                        </div>


                    
                        <Link to="/news" className="btn btn-warning">
                            <i className="fa fa-arrow-left"></i> Annuler
                        </Link>
                        &nbsp;
                        <button style={{float:'right'}}  type="submit" className="btn btn-success">Enregistrer <i className="fa fa-save"></i></button>
                     
                    </form>
                </div>
               
 
      
            </div>
           
           
        </div></div>
    );
}

export default EditNews;

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';

import './newsdetails.css'
import { Card } from 'react-bootstrap';
const PostDetails = () => {
    const { id } = useParams();
    const [post, setPost] = useState();
    const [img, setImg] = useState(null)
   
    useEffect(() => {
        axios.get(`https://alfalleh.orange.tn/api/news/details/${id}`).then(result => {
             
            setPost(result.data.news[0]);
            setImg(`https://alfalleh.orange.tn/images/${result.data.news[0].image}`)
         
          
     
        })
    }, [id]);

    return (

        <div className='container mt-2'>
          

       <Link to="/news" className="btn btn-outline-warning">
                            <i className="fa fa-arrow-left"></i> Retourner
                        </Link>
                        <br/>
                        <br/>
<Card className="text-center">
  
  <Card.Body>
    <Card.Title>  <img alt="scan" style={{width:400, height:200}} src={img} ></img>
    <h3>{post?.titre}</h3>
    
    </Card.Title>
    <small className="form-text ">Créé le : { moment.unix(post?.createdAt).format("DD/MM/YYYY")}</small>
    <Card.Text>
 <div>{post?.description=== "undefined" ?<p></p>: <p >{post?.description}</p>}</div>
   
       <div> {post?.url=== "undefined" ? <p></p>: <p style={{color: 'blue'}}>{post?.url}</p>} </div>   
        
      
       

    </Card.Text>

  
    
 
  </Card.Body>

</Card>

      
        </div>
    );
}

export default PostDetails;


import React, { Component } from "react";
import "./Login.css";
import { Image } from 'react-bootstrap';
import logo from './logo.png';

import { Redirect } from "react-router-dom";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      islogged: false,
      loginParams: {
        user_id: "",
        user_password: ""
      }
    };
  }
  handleFormChange = event => {
    let loginParamsNew = { ...this.state.loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;
    this.setState({
      loginParams: loginParamsNew
    });
  };

  login = event => {
    let user_id = this.state.loginParams.user_id;
    let user_password = this.state.loginParams.user_password;
    let user_role = "admin";
    let user_role2 = "presse";
    let pw= "12AiLneIs!O893";
    let pw2="I12aNESli!O792"

    if ((user_id === "admin" && user_password === pw)|| (user_id === "presse" && user_password ===pw2) )  {

      if (user_id === "admin") {
        localStorage.setItem("role", user_role);
      } else {
        localStorage.setItem("role", user_role2);
      }
      localStorage.setItem("token", "T");
      localStorage.setItem("userId", user_id );
   
     
      this.setState({
        islogged: true
      });
    }
 
    event.preventDefault();
  };
  render() {
    if ((localStorage.getItem("role") ==="admin") || (localStorage.getItem("role") ==="presse")){
      return(<Redirect to="/" />) 
    }
    return ( 
    <div className="maincontainer">
    <div className="container-fluid">
        <div className="row no-gutter">
           
            <div className="col-md-6 d-none d-md-flex">  <Image src={logo} thumbnail style={{border:"none"}} /> </div>


            
            <div className="col-md-6 bg-light">
                <div className="login d-flex align-items-center py-5">

                   
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-xl-7 mx-auto">
                                <h2 style={{width:"80%", marginLeft:"10%", marginTop:"20%"}} >Bienvenue dans l'espace UTAP</h2>
                                
                                <form onSubmit={this.login} > 
                                    <div className="form-group mb-3">
                                        <input id="inputEmail"  name="user_id" onChange={this.handleFormChange} type="text" placeholder="Adresse email" required="" autoFocus="" className="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <input id="inputPassword" onChange={this.handleFormChange}  name="user_password" type="password" placeholder="Mot de passe" required="" className="form-control rounded-pill border-0 shadow-sm px-4 text-primary" />
                                    </div>
                                   
                                    <input type="submit" className="btn btn-success btn-block text-uppercase mb-2 rounded-pill shadow-sm" value="Connexion"/>
                                   
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
  </div>
   
    );
  }
}
export default Login;

